<template>
    <v-dialog
        v-model="dialog"
        @click:outside.stop="close()"
        @keydown.esc="close()"
        max-width="400px"
    >
        <v-sheet color="white" elevation="0" height="100%" width="100%">
            <v-alert border="bottom" class="ma-0" color="error" text>
                <h3 class="text-h5 text-center">{{ config.title }}</h3>
                <div class="mb-8 text-center">{{ config.message }}</div>
                <div class="mb-8 text-center text--secondary">{{ config.target }}</div>
                <v-row align="center" justify="center" no-gutters>
                    <v-col class="shrink">
                        <v-btn
                            @click.stop="$emit('confirmed')"
                            color="error" text
                        >Yes</v-btn>
                    </v-col>
                    <v-col class="shrink">
                        <v-btn
                            @click.stop="close()"
                            color="secondary" text
                        >No</v-btn>
                    </v-col>
                </v-row>
            </v-alert>
        </v-sheet>
    </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
        type: Boolean,
        required: true
    },
    config: {
        type: Object,
        required: true
    },
    payload: {
        type: Object,
        required: true
    }
  },
  methods: {
    close () {
        this.$emit('update:dialog', false)
    },
  },
}
</script>